import React from 'react';
import { graphql, useStaticQuery } from "gatsby"
import Link from 'gatsby-link';
import Layout from '../components/layout/layout';
import '../styles/index.scss';
import danielBg from '../assets/images/daniel_bg.jpg';

import video from '../assets/videos/background.mp4';

const IndexPage = ({ location }) => {
  const data = useStaticQuery(graphql`
    query HomePage {
      allContentfulGeneral {
        edges {
          node {
            heroTitle {
              heroTitle
            }
            heroSubtitle {
              heroSubtitle
            }
          }
        }
      }
    }
  `)

  const generalData = data.allContentfulGeneral.edges[0].node

  return <Layout location={location}>
    <div>
      <div className="IntroBox">
        <div className="IntroBox__content">
          <div className="IntroBox__box">
            <div className="IntroBox__video-overlay" />
            <video
              className="IntroBox__video"
              autoPlay
              muted
              loop
              poster={danielBg}
            >
              <source src={video} type="video/mp4" />
            </video>
            <div
              className="IntroBox__image"
              style={{ backgroundImage: `url(${danielBg})` }}
            />
          </div>
          <div className="IntroBox__text-content">
            <h1 className="IntroBox__title">
              {generalData.heroTitle.heroTitle}
            </h1>
            <p className="IntroBox__subtitle">
              {generalData.heroSubtitle.heroSubtitle}
            </p>
            <div className="IntroBox__buttons">
              <Link className="IntroBox__button" to="/about">
                I want to know more
              </Link>
              <Link
                className="IntroBox__button IntroBox__button--blog"
                to={'/blog'}
              >
                I want to see your blog
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
}

export default IndexPage